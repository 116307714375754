import React, { useEffect, useState } from "react";
import Footer from "./Footer";

const RotatedImages = () => {
  const [isInView, setIsInView] = useState(true); // Track if container is in view

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting); // Update state based on visibility
    });
    const container = document.querySelector(".rotatedContainer");
    observer.observe(container); // Observe the rotatedContainer

    return () => {
      observer.disconnect(); // Cleanup observer on unmount
    };
  }, []);

  const brandsImage = [
    {
      IMPACT:
        "https://ds-energy.s3.amazonaws.com/Gym/Products/2024_09_03T09_12_44%20452755574_1005109934952990_2396096950081018925_n.jpg",
    },
    {
      INVICTUS:
        "https://ds-energy.s3.amazonaws.com/Gym/Products/2024_09_02T09_36_20%20454562119_1014173104046673_5185274817360054263_n.jpg",
    },
    {
      "YAVA LABS":
        "https://ds-energy.s3.amazonaws.com/Gym/Products/2024_09_03T09_44_51%20454465838_1014159094048074_2272845646936547076_n.jpg",
    },
    {
      GSN: "https://ds-energy.s3.amazonaws.com/Gym/Products/2024_10_28T11_58_55%20461720340_1054898529974130_6155643240198302679_n.jpg",
    },
    {
      "LIFE PRO":
        "https://ds-energy.s3.amazonaws.com/Gym/Products/2024_09_03T09_14_14%20453052036_1007883664675617_7630348588776424835_n.jpg",
    },
    {
      PN: "https://ds-energy.s3.amazonaws.com/ds-energy+Products/Complements/1.jpg",
    },
    {
      img: "https://ds-energy.s3.amazonaws.com/Gym/Products/Whey+Boost+Invictus+Redline.jpg",
    },
    {
      img2: "https://ds-energy.s3.amazonaws.com/Gym/Products/2024_09_03T09_41_46%20453716944_1007883894675594_6959269415571217698_n.jpg",
    },
  ];

  return (
    <div className="rotatedBody" style={{ position: "relative" }}>
      <div
        className="rotatedContainer"
        style={{ animationPlayState: isInView ? "running" : "paused" }}
      >
        {brandsImage.map((brand, index) => {
          const brandName = Object.keys(brand)[0]; // Get brand name dynamically
          const imageUrl = brand[brandName]; // Get image URL dynamically
          return (
            <span key={index} style={{ "--i": index + 1 }}>
              <img src={imageUrl} alt={brandName} loading="lazy" />
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default RotatedImages;
