import { useSelector } from "react-redux";
import { Navbar, Main, Product, Footer } from "../components";
import ImageSplit from "../components/ImageSplit";
import ImageTextSplit from "../components/ImageTextSplit";
import ImageTextSplitLeft from "../components/ImageTextSplitLeft";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import RotatedImages from "../components/RotatedImages";
import HomeHeader from "../components/HomeHeader";
function Home() {
  const produits = useSelector((state) => state.produits.list.slice(0, 7));
  const navigate = useNavigate();

  console.log(produits);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const AllBrandDisplay = () => {
    const brandsImage = [
      {
        IMPACT:
          "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/IMPACT.png",
      },
      {
        INVICTUS:
          "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/Invictus.png",
      },
      {
        "YAVA LABS":
          "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/Yava-Labs.png",
      },
      {
        GSN: "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/GSN-logo-1.png",
      },
      {
        "LIFE PRO":
          "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/Life+Pro.png",
      },
      {
        PN: "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/PN+Logo.png",
      },
    ];
    return (
      <div
        className="brand-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "5%",
          padding: "40px 0", // Padding for space
          marginBottom: "5%", // Optional space between sections
          overflow: "hidden", // Prevent overflow of images
          backgroundColor: "#f7f7f7", // Optional background color

          width: "90%", // Ensure the container takes full width
          position: "relative",
        }}
      >
        <label>
          <h2
            className="brand-title"
            style={{
              fontSize: "2rem", // Adjust title font size
              marginBottom: "20px", // Space between title and list
              fontWeight: "bold",
              color: "#333", // Adjust title color
              textAlign: "center", // Center align the title
            }}
          >
            Nos Marques de Confiance
          </h2>
        </label>
        <div
          className="brand-scroll"
          style={{
            display: "flex",
            backgroundColor: "#f7f5f5", // Optional background color
            padding: "0 20px", // Padding around brand images
            width: "max-content", // Prevents the images from overflowing
          }}
        >
          {brandsImage.map((brand, index) => {
            const brandName = Object.keys(brand)[0];
            const brandImage = brand[brandName];

            return (
              <div
                key={index}
                className="brand-logo-container"
                style={{
                  margin: "0 40px", // Space between the logos
                  flexShrink: 0, // Prevent images from shrinking
                }}
              >
                <img
                  src={brandImage}
                  alt={brandName}
                  style={{
                    width: "125px", // Adjusted size for brand images
                    height: "60px", // Keep a consistent height
                    objectFit: "contain",
                    filter: "grayscale(100%)", // Apply grayscale effect
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="col-12">
      <Navbar />
      <Main />
      <HomeHeader />
      <div style={{ marginTop: "5%", marginBottom: "5%" }}>
        <div style={{ marginBottom: "3%" }}>
          <div className="col-12" style={{ marginTop: "40px" }}>
            <AllBrandDisplay /> {/* Added margin-top for spacing */}
            <h3
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <label>Nouveautés</label>
            </h3>
            <div
              style={{
                height: "1px", // Thickness of the line
                width: "100%", // Width of the line
                paddingLeft: "10%", // Adjusted padding for the line
                paddingRight: "10%", // Adjusted padding for the line
                background:
                  "linear-gradient(to right, transparent,rgb(10, 10, 10), transparent)", // Gradient with transparent ends and yellow in the middle
              }}
            />
            <p
              className="text-center"
              style={{ margin: "20px 40px", paddingTop: "1%" }}
            >
              Découvrez les dernières innovations et ajouts à notre gamme de
              produits sur <strong>DS Energy Pro</strong>. Nos nouveautés sont
              spécialement sélectionnées pour vous offrir les meilleures options
              en matière de suppléments et de nutrition sportive.
            </p>
            <p className="text-center" style={{ margin: "20px 40px" }}>
              Restez à la pointe de votre entraînement avec nos derniers
              produits, conçus pour répondre aux besoins évolutifs des athlètes
              et des passionnés de fitness. Explorez ce que{" "}
              <strong>DS Energy Pro</strong> a de mieux à offrir pour soutenir
              votre performance et votre bien-être.
            </p>
          </div>
        </div>

        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          transitionDuration={1000}
        >
          {produits.map((item, index) => (
            <div onClick={() => navigate("/product/" + item._id)}>
              <img
                key={index}
                src={item.url}
                alt={`Visual ${index + 1}`}
                className="img-fluid"
                style={{ width: "90%", marginLeft: "5%", cursor: "pointer" }}
              />
            </div>
          ))}
        </Carousel>
      </div>
      <RotatedImages />

      <ImageTextSplit
        imageSrc="https://ds-energy.s3.amazonaws.com/ds-energy+Products/Cover/fb61ec73-90a7-4cf4-a0ca-dfe60e608abe.webp"
        text="Si vous recherchez une solution pour maximiser votre croissance musculaire et améliorer vos entraînements, nous avons les protéines parfaites pour vous. Nos gainers et isolats sont conçus pour vous fournir la puissance nécessaire pour atteindre vos objectifs de force et de performance. Pour un coup de boost supplémentaire lors de vos séances d’entraînement, découvrez notre créatine micronisée qui vous aidera à surpasser vos limites. Et si votre objectif est de sculpter votre silhouette tout en préservant votre énergie, nos brûleurs de graisse avancés sont là pour stimuler votre métabolisme, contrôler votre appétit et favoriser une perte de graisse ciblée. Choisissez parmi notre gamme soigneusement élaborée pour intégrer facilement ces suppléments dans votre routine quotidienne et obtenir des résultats visibles."
      />
      <div style={{ marginTop: 30 }}></div>
      <ImageTextSplitLeft
        imageSrc="https://ds-energy.s3.amazonaws.com/Press.png"
        text={`Nous proposons une gamme complète de suppléments conçus pour vous aider à atteindre vos objectifs de performance et de bien-être.
`}
      />

      <ImageSplit
        imageSrc1={
          "https://ds-energy.s3.amazonaws.com/Gym/Products/2024_09_03T09_12_44%20452755574_1005109934952990_2396096950081018925_n.jpg"
        }
        imageSrc2={
          "https://ds-energy.s3.amazonaws.com/Gym/Products/2024_09_02T09_36_20%20454562119_1014173104046673_5185274817360054263_n.jpg"
        }
      />
      <Product />
      <Footer />
    </div>
  );
}

export default Home;
