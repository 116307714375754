import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../redux/actions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as produitActions from "../redux/actions/Produit/index";
import { useNavigate, Link } from "react-router-dom";
import { FaShoppingCart, FaTrash, FaInfoCircle } from "react-icons/fa";
import { message } from "antd";

const Products = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const produits = useSelector((state) => state.produits);
  const [filter, setFilter] = useState([]);

  let componentMounted = true;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
    message.success("Produit Bien Ajouté au Panier");
  };
  // const deleteProduct = (product) => {
  //   dispatch(produitActions.deleteProduit(product));
  // };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await fetch(
        "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/produits"
      );
      dispatch(produitActions.fetchProduits());
      if (componentMounted) {
        setData(await response.clone().json());
        //setFilter(await response.json());
        setLoading(false);
      }

      return () => {
        componentMounted = false;
      };
    };

    getProducts();
  }, []);
  useEffect(() => {
    const groupByMark = (products) => {
      return products.reduce((acc, product) => {
        const mark = product.mark || "Unspecified"; // Handle products without a mark
        if (!acc[mark]) {
          acc[mark] = [];
        }
        acc[mark].push(product);
        return acc;
      }, {});
    };
    const groupedProducts = groupByMark(produits.list); // Group products by mark
    console.log("888", groupedProducts);
    setFilter(groupedProducts);
  }, [produits]);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = produits.list.filter((item) => item.categorie === cat);
    setFilter(updatedList);
  };

  const brandsImage = [
    {
      name: "IMPACT",
      image:
        "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/IMPACT.png",
      description:
        "IMPACT propose des suppléments de protéines de haute qualité, conçus pour améliorer vos performances sportives. Chaque produit est formulé avec des ingrédients naturels, visant à soutenir la croissance musculaire, la récupération et l'endurance. Idéal pour les athlètes et les passionnés de fitness qui cherchent à optimiser leurs résultats.",
    },
    {
      name: "INVICTUS",
      image:
        "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/Invictus.png",
      description:
        "INVICTUS offre des solutions puissantes et innovantes pour les athlètes et les pratiquants de fitness. Leurs produits sont spécialement conçus pour booster la force, l'énergie et la performance. Avec des formules avancées et des ingrédients de qualité supérieure, INVICTUS vous aide à franchir les limites de vos capacités physiques.",
    },
    {
      name: "YAVA LABS",
      image:
        "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/Yava-Labs.png",
      description:
        "YAVA LABS est une marque qui se distingue par son approche innovante de la santé et du fitness. Leurs suppléments sont élaborés avec des ingrédients naturels et scientifiquement prouvés pour améliorer votre bien-être général. Que ce soit pour la gestion du poids, l'énergie ou la récupération, YAVA LABS répond à tous vos besoins en matière de nutrition sportive.",
    },
    {
      name: "GSN",
      image:
        "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/GSN-logo-1.png",
      description:
        "GSN est une marque de confiance pour les athlètes sérieux qui recherchent des produits efficaces et de haute qualité. Leurs suppléments sont conçus pour maximiser la performance physique, en particulier dans les sports exigeants. Avec une gamme qui va des protéines aux stimulants énergétiques, GSN vous accompagne dans chaque étape de votre parcours sportif.",
    },
    {
      name: "LIFE PRO",
      image:
        "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/Life+Pro.png",
      description:
        "LIFE PRO se spécialise dans la création de produits naturels et efficaces pour un mode de vie actif. Leur gamme comprend des protéines, des vitamines, des minéraux et des suppléments pour soutenir vos efforts physiques et mentaux. LIFE PRO est le choix idéal pour ceux qui cherchent à adopter une approche plus saine et naturelle de leur nutrition sportive.",
    },
    {
      name: "PN",
      image:
        "https://ds-energy-pro.s3.us-east-1.amazonaws.com/Brands/PN+Logo.png",
      description:
        "PN (Pro Nutrition) est une marque premium dédiée aux athlètes et aux passionnés de sport. Leurs produits sont formulés avec des ingrédients de qualité supérieure, offrant une nutrition complète pour soutenir la croissance musculaire, l'endurance et la récupération. PN est un choix de confiance pour ceux qui recherchent des résultats optimaux dans leurs entraînements.",
    },
  ];

  const BrandDisplay = ({ brandName }) => {
    const brand = brandsImage.find((b) => b.name === brandName) || {
      image: "https://ds-energy.s3.amazonaws.com/Logo.jpg",
      description:
        "Atteignez de nouveaux sommets avec nos produits de nutrition sportive de haute qualité.",
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row", // Default: side by side
          alignItems: "center",
          marginBottom: "15px",
          padding: "10px",
          flexWrap: "wrap", // Allow wrapping on small screens
        }}
      >
        <img
          src={brand.image}
          alt={brandName}
          style={{
            maxWidth: "250px",
            maxHeight: "120px",
            objectFit: "contain",
            paddingBottom: "20px",
            paddingTop: "5px",
            marginRight: "15px",
            flexShrink: 0, // Prevent shrinking
          }}
        />
        <div
          style={{
            flexGrow: 1, // Allow the text to take available space
            minWidth: "0", // Prevent text container from shrinking too much
            padding: "10px",
          }}
        >
          <h3 style={{ margin: 0, fontWeight: "bold", color: "#333" }}>
            {brandName === "undefined" ? "DS-Energy-Pro" : brandName}
          </h3>
          <p style={{ margin: 0, color: "#666", fontSize: "13px" }}>
            {brand.description.length > 250
              ? `${brand.description.slice(0, 250)}...`
              : brand.description}
          </p>
        </div>
      </div>
    );
  };

  const AllBrandDisplay = () => {
    return (
      <div style={{ paddingTop: 50, paddingBottom: 50 }}>
        <div
          className="brand-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px 0", // Padding for space
            backgroundColor: "#f7f7f7", // Optional background color
            marginBottom: "40px", // Optional space between sections
            overflow: "hidden", // Prevent overflow of images
            width: "100%", // Ensure the container takes full width
            position: "relative",
          }}
        >
          <h2
            className="brand-title"
            style={{
              fontSize: "2rem", // Adjust title font size
              marginBottom: "20px", // Space between title and list
              fontWeight: "bold",
              color: "#333", // Adjust title color
            }}
          >
            Our Trusted Brands
          </h2>

          <div
            className="brand-scroll"
            style={{
              display: "flex",
              animation: "scroll 20s linear infinite", // Continuous scrolling animation
              padding: "0 20px", // Padding around brand images
              width: "max-content", // Prevents the images from overflowing
            }}
          >
            {brandsImage.map((brand, index) => {
              const brandName = Object.keys(brand)[0];
              const brandImage = brand[brandName];

              return (
                <div
                  key={index}
                  className="brand-logo-container"
                  style={{
                    margin: "0 40px", // Space between the logos
                    flexShrink: 0, // Prevent images from shrinking
                  }}
                >
                  <img
                    src={brandImage}
                    alt={brandName}
                    style={{
                      width: "250px", // Adjusted size for brand images
                      height: "120px", // Keep a consistent height
                      objectFit: "contain",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const ShowProducts = () => {
    return (
      <>
        {/* <div className="buttons text-center py-5">
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => setFilter(produits.list)}
          >
            All
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Compléments")}
          >
            Compléments
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Protéines")}
          >
            Protéines
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Vitamines")}
          >
            Vitamines
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Snacks")}
          >
            Snacks
          </button>
        </div> */}

        {Object.keys(filter).map((mark) => (
          <div key={mark} className="mb-5">
            <h3 className="text-xl font-bold mb-4">
              <BrandDisplay brandName={mark} />
            </h3>
            <div className="row">
              {filter[mark].map((item) => (
                <div
                  id={item._id}
                  key={item._id}
                  className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4"
                >
                  <div className="card text-center h-100 border border-gray-300 rounded-lg shadow-lg">
                    <div
                      onClick={() => navigate("/product/" + item._id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="image-container d-flex justify-content-center">
                        <img
                          style={{ height: 300, width: "100%" }}
                          src={item.url}
                          alt={item.name}
                        />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title text-lg font-semibold">
                          {item.name.length > 12
                            ? item.name.substring(0, 12) + "..."
                            : item.name}
                        </h5>
                        <p className="card-text text-gray-700">
                          {item.description.length > 90
                            ? item.description.substring(0, 90) + "..."
                            : item.description}
                        </p>
                      </div>
                      <div className="gradient-line"></div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item lead bg-yellow-100">
                          <b>{item.prix} TND</b>
                        </li>
                      </ul>
                      <div className="gradient-line"></div>
                    </div>
                    <div className="card-body d-flex flex-wrap justify-content-center">
                      <Link
                        to={"/product/" + item._id}
                        className="btn btn-dark m-1 white-ss transition-colors duration-300 d-flex align-items-center"
                      >
                        <FaInfoCircle className="me-2" />
                        Produit
                      </Link>
                      <button
                        className="btn btn-dark m-1 white-ss transition-colors duration-300 d-flex align-items-center"
                        onClick={() => addProduct(item)}
                      >
                        <FaShoppingCart className="me-2" />
                        Ajouter au panier
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <div className="card text-center h-100">
            <img
              className="card-img-top p-3"
              src={
                "https://ds-energy.s3.amazonaws.com/Gym/Products/Product4.jpg"
              }
              alt="Card"
              height={400}
            />
            <div className="card-body">
              <h5 className="card-title"></h5>
              <p className="card-text"></p>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item lead">$ </li>
              
            </ul>
            <div className="card-body">
              <Link to={"/product/"} className="btn btn-dark m-1">
                Buy Now
              </Link>
              <button className="btn btn-dark m-1">Add to Cart</button>
            </div>
          </div>
        </div> */}
      </>
    );
  };
  return (
    <>
      <div className="container my-3 py-3">
        {/* <AllBrandDisplay /> */}
        <div className="row">
          <div className="col-12">
            <h2
              className="display-5 text-center"
              style={{ fontWeight: "bold" }}
            >
              <label>Nos Produits</label>
            </h2>
            <hr />
            <p
              className="text-center"
              style={{ margin: "20px 0", fontSize: "1.2rem" }}
            >
              Découvrez notre gamme complète de produits sur{" "}
              <strong>DS Energy Pro</strong>, spécialement conçus pour répondre
              à tous vos besoins en matière de fitness. Que vous soyez un
              athlète chevronné ou un débutant, nos produits de qualité
              supérieure vous aideront à optimiser vos performances et à
              atteindre vos objectifs de santé.
            </p>
            <p
              className="text-center"
              style={{ margin: "20px 0", fontSize: "1.2rem" }}
            >
              Explorez nos catégories de protéines, gainers, et compléments
              alimentaires, tous formulés pour vous fournir l'énergie et les
              nutriments nécessaires pour exceller lors de vos entraînements.
              Sur <strong>DS Energy Pro</strong>, nous nous engageons à vous
              offrir les meilleurs suppléments pour soutenir votre parcours de
              fitness.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          {loading || filter.length === 0 ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;
