import React, { useState, useEffect } from "react";

export default function HomeHeader() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Check if screen width is less than or equal to 768px
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for window resizing

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="newBody">
      <div className="col-12" style={{ paddingLeft: "7%", paddingRight: "7%" }}>
        {/* Added margin-top for spacing */}
        <h2
          style={{ fontWeight: "bold", margin: "20px 0", textAlign: "center" }}
        >
          <label>DS Energy Pro</label>
        </h2>
        <hr />
        <p
          className="text-center"
          style={{ margin: "20px 20px", fontSize: "1rem" }}
        >
          Chez <strong>DS Energy Pro</strong>, nous nous engageons à vous offrir
          des suppléments de la plus haute qualité pour soutenir votre parcours
          de fitness. Que vous souhaitiez gagner en masse musculaire, augmenter
          votre énergie, ou améliorer votre endurance, nous avons ce qu'il vous
          faut.
        </p>
        <p
          className="text-center"
          style={{ margin: "30px 40px", fontSize: "1rem" }}
        >
          Explorez notre large gamme de produits, tous conçus avec soin pour
          vous aider à atteindre vos objectifs. Découvrez pourquoi{" "}
          <strong>DS Energy Pro</strong> est le choix privilégié des passionnés
          de fitness et des athlètes à travers le pays.
        </p>
      </div>

      {/* Conditionally render images */}
      {!isMobile && (
        <div className="homeContainer">
          <img src={require("../assets/Home/gym1.jpg")} alt="office" />
          <img src={require("../assets/Home/gym2.jpg")} alt="office" />
          <img src={require("../assets/Home/gym3.jpg")} alt="office" />
          <img src={require("../assets/Home/gym4.jpg")} alt="office" />
          <img src={require("../assets/Home/gym5.jpg")} alt="office" />
        </div>
      )}
    </div>
  );
}
