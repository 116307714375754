import React from "react";

const ImageTextSplit = ({ imageSrc, text }) => {
  const descriptionSegments = text.replace(/\./g, ".<br><br>");

  return (
    <div
      style={{
        width: "98%", // Ensure the container takes full width
        margin: "0 auto", // Center the container
        padding: "10", // Remove any padding
        boxSizing: "border-box", // Ensure padding and borders are included in width
        overflow: "hidden", // Prevent any overflow
        paddingTop: "80px",
      }}
    >
      {/* <div className="col-12" style={{ marginTop: "40px" }}>
        {" "}
        <h2
          style={{ fontWeight: "bold", margin: "20px 0" }}
          className="display-1 text-center"
        >
          <label>DS Energy Pro</label>
        </h2>
        <hr />
        <p
          className="text-center"
          style={{ margin: "20px 20px", fontSize: "1.2rem" }}
        >
          Chez <strong>DS Energy Pro</strong>, nous nous engageons à vous offrir
          des suppléments de la plus haute qualité pour soutenir votre parcours
          de fitness. Que vous souhaitiez gagner en masse musculaire, augmenter
          votre énergie, ou améliorer votre endurance, nous avons ce qu'il vous
          faut.
        </p>
        <p
          className="text-center"
          style={{ margin: "30px 40px", fontSize: "1.2rem" }}
        >
          Explorez notre large gamme de produits, tous conçus avec soin pour
          vous aider à atteindre vos objectifs. Découvrez pourquoi{" "}
          <strong>DS Energy Pro</strong> est le choix privilégié des passionnés
          de fitness et des athlètes à travers le pays.
        </p>
      </div> */}

      <div
        className="row no-gutters"
        style={{
          display: "flex", // Flexbox to control image placement
        }}
      >
        {/* Ensures no gutter spacing between columns */}
        <div className="col-md-6">
          <div
            style={{
              boxShadow: "0px 0px 10px 4px rgba(252, 255, 106, 0.7)", // Subtle shadow, smaller size
              overflow: "hidden", // Prevent overflow
              borderRadius: "8px", // Rounded corners
              width: "100%", // Occupies full width of the column
              height: "auto",
            }}
          >
            <img
              src={imageSrc}
              alt="Visual"
              className="img-fluid"
              style={{
                width: "100%", // Full width, stays within column
                height: "auto", // Auto height to maintain aspect ratio
                display: "block", // Block-level element for better control
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            />
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label>
            <div
              className="lead p-4" // Using Bootstrap padding class
              style={{
                margin: "0", // Remove default margin
                boxSizing: "border-box", // Ensure padding is accounted for in width
                wordBreak: "break-word", // Break long words to prevent overflow
              }}
            >
              <h2>Maximisez Votre Développement Musculaire</h2>
              <p>
                Si vous recherchez une solution pour maximiser votre croissance
                musculaire et améliorer vos entraînements, découvrez nos
                protéines de haute qualité. Nos gainers et isolats sont
                spécialement conçus pour vous fournir la puissance nécessaire
                pour atteindre vos objectifs de force et de performance.
              </p>

              <h3>Boostez Votre Performance avec la Créatine</h3>
              <p>
                Pour un coup de boost supplémentaire lors de vos séances
                d’entraînement, essayez notre créatine micronisée. Elle vous
                aidera à surpasser vos limites et à optimiser vos performances.
              </p>

              <h3>Sculptez Votre Silhouette avec Nos Brûleurs de Graisse</h3>
              <p>
                Si votre objectif est de sculpter votre silhouette tout en
                préservant votre énergie, nos brûleurs de graisse avancés
                stimulent votre métabolisme, contrôlent votre appétit, et
                favorisent une perte de graisse ciblée.
              </p>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ImageTextSplit;
