import React from "react";
import { useNavigate } from "react-router-dom";

const ImageSplit = ({ imageSrc1, imageSrc2 }) => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div
        className="row"
        style={{
          marginBottom: "3%",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className="col-12">
          <div
            style={{
              height: "1px", // Thickness of the line
              width: "100%", // Width of the line
              background:
                "linear-gradient(to right, transparent, gray, transparent)",
            }}
          />
          <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
            <label>Découvrez nos best-sellers !</label>
          </h2>
          <div
            style={{
              height: "1px", // Thickness of the line
              width: "100%", // Width of the line
              background:
                "linear-gradient(to right, transparent, gray, transparent)",
            }}
          />
        </div>
        <div
          className="col-12"
          style={{ paddingLeft: "7%", paddingRight: "7%" }}
        >
          <p className="text-center" style={{ margin: "20px 40px" }}>
            Explorez notre sélection des produits les plus populaires sur{" "}
            <strong>DS Energy Pro</strong>, soigneusement choisis pour vous
            aider à atteindre vos objectifs de fitness. Nos best-sellers
            incluent des protéines de haute qualité, des gainers, et des
            brûleurs de graisse, tous formulés pour maximiser votre performance
            et favoriser une récupération optimale.
          </p>
          <p className="text-center" style={{ margin: "20px 40px" }}>
            Que vous cherchiez à renforcer votre masse musculaire, à booster
            votre énergie lors de vos entraînements, ou à affiner votre
            silhouette, nos produits plébiscités par nos clients sur{" "}
            <strong>DS Energy Pro</strong> vous fourniront les nutriments
            essentiels pour exceller. N'attendez plus, découvrez pourquoi tant
            de passionnés de fitness choisissent nos best-sellers !
          </p>
        </div>
      </div>
      <div
        className="col-12 row justify-content-center align-items-center"
        style={{
          alignContent: "center",
          alignSelf: "center",
          overflow: "hidden",
        }}
      >
        <div
          className="col-md-6 d-flex justify-content-center"
          style={{ padding: "10px" }}
          onClick={() => navigate("/product/66d6d30d2513dbaa35ed4a4e")}
        >
          {" "}
          {/* Add padding */}
          <img
            src={imageSrc1}
            alt="Visual"
            className="img-fluid"
            style={{
              boxShadow: "0px 0px 10px 5px gray",
              maxWidth: "100%",
              height: "auto",
              cursor: "pointer",
            }}
          />
        </div>
        <div
          className="col-md-6 d-flex justify-content-center"
          style={{ padding: "10px" }}
          onClick={() => navigate("/product/66d5871596f411565414ac69")}
        >
          {" "}
          {/* Add padding */}
          <img
            src={imageSrc2}
            alt="Visual"
            className="img-fluid"
            style={{
              boxShadow: "0px 0px 10px 5px gray",
              maxWidth: "100%",
              height: "auto",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageSplit;
